@import 'constants/styles.constants.module';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.375rem;
  height: 4.375rem;
}

.warning {
  background-color: $colorWarning;
}

.info {
  background-color: $colorSecondary;
}

svg.icon {
  height: 1.5rem;
  @include ie-only() {
    width: 1.5rem;
  }
}
