@import 'constants/styles.constants.module';

@keyframes width-change {
  0% {
    width: 0;
  }

  3% {
    width: 40%;
  }

  30% {
    width: 60%;
  }

  33% {
    width: 61%;
  }

  35% {
    width: 70%;
  }

  100% {
    width: 100%;
  }
}

.loadingBar {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 0.17rem;

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #f00;
    box-shadow: 0 0 5px 0 #000;
    animation: width-change 30s linear 0s;
  }
}
