@import 'constants/styles.constants.module';

.root {
  margin: 0;
  font-weight: $fontWeightLight;
  font-size: $fontSizeTitle2;
  line-height: 2.812rem;
  text-align: left;

  @include max(s) {
    font-size: $fontSizeTitle3;
  }
}

.smaller {
  font-weight: $fontWeightNormal;
  font-size: $fontSizeText;
}
