@import 'constants/styles.constants.module';

.root {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  @include ie-only() {
    input,
    select {
      border: inherit;
    }

    input::-ms-clear,
    select::-ms-clear {
      display: none;
    }

    input[type='date'] {
      width: 5rem;
    }
  }

  input:disabled {
    background-color: $colorInputDisabled;
  }
}

.withBottomBorder {
  border-bottom: 1px solid $colorBorder;
}

.input {
  flex-grow: 1;
  max-width: 100%;
  height: 2rem;
  padding-left: 1rem;
  color: var(--colorSecondary);
  font-weight: $fontWeightMedium;
  font-size: $fontSizeText;
  text-align: left;
  background: $colorWhite;
  border: unset;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $colorMain;
    font-weight: $fontWeightLight;
  }
}

.input-date[type='date'] {
  &::-webkit-calendar-picker-indicator {
    opacity: 0;
  }
}

.inputWithIcon {
  padding-right: 2rem;
}

.icon {
  height: 1.125rem;
  margin-right: 0.625rem;
}

.searchIcon {
  position: absolute;
  top: 50%;
  right: 1rem;
  width: 3.7rem;
  height: 3.7rem;
  transform: translateY(-50%);
}

.inputIcon {
  position: absolute;
  right: 4px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  user-select: none;

  @include ie-only() {
    top: 0.55rem;
    right: 3px;
    width: 2rem;
  }

  svg {
    display: block;
    height: 5px;
    stroke: $colorSecondary;
  }

  div {
    height: 5px;
    margin-top: 2px;
    padding-right: 9px;
    padding-left: 9px;
  }

  div:hover {
    cursor: pointer;
  }

  div:first-child {
    margin-top: 0;
    margin-bottom: 2px;
  }

  div:first-child svg {
    transform: rotate(180deg);
  }
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
  appearance: none;
}

.input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.input[type='date'] {
  z-index: 2;
  background: $colorWhite;
}

.input::-webkit-calendar-picker-indicator {
  margin: 0;
  background-color: $colorWhite;

  &::after {
    display: block;
    width: 1rem;
    height: 1rem;
    background-color: #c9c;
  }
}

.whiteBackground {
  border-radius: 1rem;
  box-shadow: $boxShadowInput;
  @include ie-only() {
    box-shadow: $boxShadowInputIE;
  }
}

.bigger {
  height: 4rem;
  font-size: $fontSizeBiggerText;
}

.biggerInputNumberIcons {
  margin-top: 0.25rem;
}

.error {
  color: $colorDarkHighlight !important;
  font-size: $fontSizeSmallestText;
}

.calendarIcon {
  position: absolute;
  right: 0.5rem;
  z-index: 1;
  width: auto;
  height: 1rem;
  @include ie-only() {
    position: relative;

    svg {
      width: 2rem;
    }
  }
}

.withBorder {
  border: 1px solid #26336055;
  border-radius: 0.5rem;
  @include ie-only() {
    border: 1px solid rgba(38, 51, 96, 0.33);
  }

  &.highlighted {
    border-color: $colorMain;
  }
}
