@import 'constants/styles.constants.module';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: $colorBackgroundAlpha90;
    border-radius: 50%;
  }

  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 75%;
      height: 75%;
      fill: var(--colorMain);
      stroke: var(--colorMain);
      stroke-width: 0.1rem;
    }
  }

  &:hover {
    .iconWrapper svg {
      fill: var(--colorSecondary);
      stroke: var(--colorSecondary);
    }
  }
}

.disabled {
  color: $colorWhite;
  background-color: $colorDisabled;
  border-color: $colorDisabled;
  pointer-events: none;
}

.offScreen {
  position: absolute;
  left: -9999px;
}
