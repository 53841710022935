@import 'constants/styles.constants.module';

.notificationContainer {
  box-sizing: border-box;
  font-size: $fontSizeText;
  position: fixed;
  z-index: 3000;

  button.delete {
    background: transparent;
    border: hidden;
    cursor: pointer;
    height: 1rem;
    outline: none;
    padding: 0;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    width: 1rem;

    svg:hover {
      stroke: #000 !important;
    }
  }
}

.hidden {
  display: none;
}

.topCenter {
  animation: toast-in-center 0.7s;
  right: calc(50% - 15rem);
  top: 5%;
  transition: transform 0.6s ease-in-out;

  @include max(xs) {
    right: 0;
    width: 99%;
  }
}

.notification {
  border-radius: 0.5rem;
  box-shadow: $boxShadowImage;
  margin: 0 0 0.5rem;
  margin-bottom: 1rem;
  padding: 1rem;
  pointer-events: auto;
  transition: 0.3s ease;
  width: 30rem;

  &:hover {
    box-shadow: $boxShadowEqual;
  }
}

.notificationTitle {
  font-size: $fontSizeBiggerText;
  font-weight: $fontWeightSemiBold;
  height: 2.3rem;
  margin-bottom: 1rem;
  margin-top: 0;
  padding-bottom: 0.5rem;
  text-align: left;
}

.withBorder {
  border-bottom: 1px solid $colorBorderAlpha30;
}

.notificationMessage {
  margin: 0;
  text-align: left;
  word-wrap: break-word;
}

.notificationImage {
  float: left;
  margin-right: 1rem;

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.toast {
  color: $colorWhite;
  width: 25rem;

  @include max(xs) {
    left: 0.5%;
    width: 99%;
  }
}

.success {
  background-color: $backgroundToastGreen;
}

.danger {
  background-color: $backgroundToastRed;
}

.warning {
  background-color: $backgroundToastOrange;
}

.info {
  background-color: $colorMain;
}

@keyframes toast-in-center {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0%);
  }
}
