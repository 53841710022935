@import 'constants/styles.constants.module';

.root {
  width: auto;
  height: 100%;
}

.strip {
  fill: none;
  stroke: $colorWhite;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1px;
}

.onHover {
  &:hover,
  &:focus {
    cursor: pointer;
  }
}

.iconOnHover {
  &:hover svg,
  &:focus svg {
    fill: var(--colorSecondary);
    stroke: var(--colorSecondary);
  }
}

svg.color-GREEN {
  fill: $colorSuccess;
  stroke: $colorSuccess;
}

svg.color-ORANGE {
  fill: $colorOrange;
  stroke: $colorOrange;
}
