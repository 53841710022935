@import 'constants/styles.constants.module';

.root {
  position: relative;
}

.cardIconRoot {
  position: absolute;
  top: -2.1rem;
  right: 0.625rem;

  @include ie-only() {
    position: relative;
    top: 0;
    right: 0;
  }

  svg.icon {
    height: 0.8rem;
    box-shadow: $boxShadowContextMenu;
    fill: var(--colorSecondary);
    stroke: var(--colorSecondary);
    stroke-width: 1.5rem;
  }

  svg.iconCircle {
    position: relative;
    width: 1rem;
    height: 1rem;
    background-color: transparent;
    border-radius: 2rem;
  }

  .label {
    color: $colorMain;
    font-weight: $fontWeightMedium;
  }

  .whiteColor {
    color: $colorWhite;

    @include max(sm) {
      color: var(--colorMain);
    }
  }

  svg.whiteStroke {
    stroke: $colorWhite;

    @include max(sm) {
      stroke: var(--colorMain);
    }
  }

  &.clickable:hover {
    svg.icon {
      fill: var(--colorSecondaryHover);
      stroke: var(--colorSecondaryHover);
    }

    .label {
      color: var(--colorSecondaryHover);
    }
  }
}

svg.marginRight {
  margin-right: 0.4rem;
}

.clickable {
  &:hover,
  &:focus {
    cursor: pointer;
  }
}

.more {
  width: 1rem;
}

div.bigger {
  display: flex;
  align-items: center;
}

div.pipeLine::before {
  opacity: 0.4;
  content: ' | ';
}

.downgraded {
  color: $colorMain !important;

  @include max(sm) {
    stroke: var(--colorMain);
  }

  svg.icon {
    stroke: $colorMain !important;
  }

  &:hover {
    color: var(--colorSecondaryHover) !important;

    svg.icon {
      fill: var(--colorSecondaryHover) !important;
      stroke: var(--colorSecondaryHover) !important;
    }
  }
}
