@import 'constants/styles.constants.module';

div.input {
  width: 4rem;
  margin-top: -0.2rem;
  padding-left: 0;

  div {
    margin: 0 !important;

    input {
      padding-right: 0.3rem;
      padding-left: 0.4rem;
      border: 0.1rem solid $colorBorderLine;
      border-radius: 0.4rem;
    }

    span {
      right: unset;
      margin-left: 2.2rem;
    }
  }
}

.positionR {
  position: relative !important;
  top: unset !important;
  right: unset !important;
}
