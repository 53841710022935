@import 'constants/styles.constants.module';

.cross {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2rem;
  height: 2rem;

  &:hover {
    cursor: pointer;
  }

  div {
    position: absolute;
    top: 1rem;
    left: 0;
    width: 100%;
    height: 0.1rem;
    background-color: $colorMain;
  }

  div:first-child {
    transform: rotate(45deg);
  }

  div:last-child {
    transform: rotate(-45deg);
  }
}
