@import 'constants/styles.constants.module';

.root {
  position: relative;
  background: $colorWhite;
  border-radius: 0.5rem;
  box-shadow: $boxShadowCard;
}

div.badge {
  position: absolute;
  top: 0;
  left: 3rem;
}
