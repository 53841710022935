@import 'constants/styles.constants.module';

.root {
  color: $colorMain;
  font-weight: $fontWeightLight;
  font-size: $fontSizeText;
}

.medium {
  font-weight: $fontWeightMedium;
}

.semibold {
  font-weight: $fontWeightSemiBold;
}

.small {
  font-size: $fontSizeSmallestText;
}

.large {
  font-size: $fontSizeBiggerText;
}

.uppercase {
  text-transform: uppercase;
}
