@import 'constants/styles.constants.module';

.root {
  background: none;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  box-shadow: $boxShadowContextMenu;
  cursor: pointer;
  font-size: $fontSizeButton;
  font-weight: $fontWeightMedium;
  height: 3.75rem;
  justify-content: center;
  min-width: 11.25rem;
  padding-left: 1.625rem;
  padding-right: 1.625rem;
  position: relative;
  text-transform: uppercase;
  transition: 0.3s;

  @include max(xs) {
    min-width: 0;
  }

  span.disabledReason {
    color: $colorDisabled;
    font-size: $fontSizeSmallestText;
    left: -5%;
    overflow: hidden;
    position: absolute;
    text-align: center;
    top: 110%;
    width: 110%;
  }

  &:focus {
    outline: none;
  }
}

.hidden {
  visibility: hidden;
}

.loader {
  left: 50%;
  position: absolute;
  top: 50%;
}

a.root {
  align-items: center;
  display: inline-flex;
  text-decoration: none;
}

.anyWidth {
  min-width: unset;
  @include ie-only() {
    min-width: inherit;
  }
}

.primary {
  background: var(--colorMain);
  border-color: var(--colorMain);
  box-shadow: $boxShadowButton;
  color: $colorWhite;

  &:hover,
  &:focus {
    background-color: var(--colorSecondaryHover);
    border-color: var(--colorSecondaryHover);
    color: $colorWhite;
  }
}

.white {
  background-color: $colorWhite;
  border-color: $colorWhite;
  color: var(--colorSecondary);

  &:hover,
  &:focus {
    border-color: var(--colorSecondaryHover);
    color: var(--colorSecondaryHover);
  }
}

.transparent {
  background-color: unset;
  border-color: var(--colorMain);
  color: var(--colorMain);
  @include ie-only() {
    background-color: transparent;
  }

  &:hover,
  &:focus {
    background-color: var(--colorSecondaryHover);
    border-color: var(--colorSecondaryHover);
    color: $colorWhite;
  }
}

.secondary {
  background: var(--colorSecondary);
  border-color: var(--colorSecondary);
  color: $colorWhite;

  &:hover,
  &:focus {
    background: var(--colorSecondaryHover);
    border-color: var(--colorSecondaryHover);
  }
}

.disabled {
  background-color: $colorDisabled;
  border-color: $colorDisabled;
  color: $colorWhite;
  pointer-events: none;
}
