@import 'constants/styles.constants.module';
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600&display=swap');
/* stylelint-disable unit-allowed-list */
body,
html {
  min-width: 320px;
  min-height: 100vh;
  margin: 0;
  color: $colorMain;
  font-family: 'Jost', sans-serif;
  background-color: var(--colorBackground);
}

* {
  box-sizing: border-box;
  font-family: 'Jost', sans-serif;
}

html {
  font-size: 16px;

  @include max(lg) {
    font-size: 15px;
  }

  @include max(md) {
    font-size: 14px;
  }
}

a {
  color: var(--colorSecondary);
  text-decoration: none;

  &:hover {
    color: var(--colorLinkHover);
  }
}

svg {
  overflow: initial;
}
/* stylelint-enable unit-allowed-list */
