@import 'constants/styles.constants.module';

.topBanner {
  position: absolute;
  top: 0;
  z-index: 450;
  display: none;
  width: 100%;
  padding: 0.2rem 0;
  color: $colorWhite;
  text-align: center;
  background-color: #0275d8;
}

.show {
  display: block;
}
