@import 'constants/styles.constants.module';

.root {
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  font-weight: $fontWeightLight;

  .iconWrap {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.rootButton {
  position: relative;
  height: max-content;
}

.iconWrap {
  display: flex;
  justify-content: center;
  width: 2rem;
  height: 100%;

  &:hover {
    cursor: pointer;
  }

  &:hover > .icon {
    fill: $colorSecondary;
  }
}

svg.icon {
  width: 1.25rem;
  fill: var(--colorMain);
  @include ie-only() {
    height: 1.25rem;
  }
}

%menushared {
  position: absolute;
  right: 0;
  z-index: 5;
  overflow: hidden;
  color: $colorMain;
  background-color: $colorWhite;
  border-radius: 0.5rem;
  box-shadow: $boxShadowContextMenu;
  @include ie-only() {
    box-shadow: $boxShadowContextMenuIE;
  }
}

.menuButton {
  @extend %menushared;
}

.menu {
  @extend %menushared;
  position: absolute;
  top: calc(50% + 1rem);
  right: 0;
  z-index: 5;
  overflow: hidden;
  background-color: $colorWhite;
  border-radius: 0.5rem;
  box-shadow: $boxShadowContextMenu;
  @include ie-only() {
    top: auto;
    box-shadow: $boxShadowContextMenuIE;
  }
}

.toTop {
  top: unset;
  bottom: 2rem;
  @include ie-only() {
    bottom: 0;
  }
}

.option {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  @include ie-only() {
    line-height: 1rem;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover,
  &:focus {
    background-color: $colorLightHover;
  }

  a {
    display: block;
    color: $colorMain;
  }

  svg {
    margin-right: 8px;
  }

  .optionInner {
    display: flex;
    align-items: center;
  }
}

.optionDisabled {
  padding: 0.5rem 1rem;
  color: $colorDisabled;
  white-space: nowrap;
  pointer-events: none;
  @include ie-only() {
    line-height: 1rem;
  }

  svg {
    margin-right: 8px;
  }

  .optionInner {
    display: flex;
    align-items: center;
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
