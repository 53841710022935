@import 'constants/styles.constants.module';

@keyframes opacity-change {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0.3;
  }
}

.root {
  display: flex;
  flex-direction: column;
}

.maxHeight {
  height: 100%;
}

.icons {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.noMarginBottom {
  margin-bottom: 0;
}

.icon {
  display: block;
  width: 7px;
  height: 7px;
  margin: 6px;
  background-color: $colorSecondary;
  border-radius: 50%;
  animation: opacity-change 0.75s linear 0s infinite; /* stylelint-disable-line unit-allowed-list */
}

.iconSecond {
  animation-delay: 0.25s;
}

.iconThird {
  animation-delay: 0.5s;
}

span.title {
  font-weight: $fontWeightLight;
  font-size: 1.875rem;
  text-align: center;
}

.wholeScreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: $colorBackgroundAlpha90;
}

.loadingWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
