@import 'constants/styles.constants.module';

.tooltip {
  display: inline-block;
  width: 100%;

  &.noWidth {
    width: auto;
  }
}

.children {
  display: flex;
}

.cursor_help {
  cursor: help;
}

.cursor_pointer {
  cursor: pointer;
}

.tooltip-container {
  --tooltipBackground: #fff;
  --tooltipBorder: #c0c0c0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  min-width: 10rem;
  max-width: 15rem;
  padding: 0.7rem;
  color: $colorBlack;
  background-color: var(--tooltipBackground);
  border: 1px solid var(--tooltipBorder);
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
  transition: opacity 0.3s;
}

.danger {
  color: $colorBlack;
  font-weight: $fontWeightMedium;
  background-color: $colorBackgroundRed;
}

.center {
  justify-content: center;
  text-align: center;
}

.tooltip-container[data-popper-interactive='false'] {
  pointer-events: none;
}

.tooltip-arrow {
  position: absolute;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
}

.tooltip-arrow::before {
  display: block;
  width: 0;
  height: 0;
  margin: auto;
  border-style: solid;
  content: '';
}

.tooltip-arrow::after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  margin: auto;
  border-style: solid;
  content: '';
}

.tooltip-container[data-popper-placement*='bottom'] .tooltip-arrow {
  top: 0;
  left: 0;
  margin-top: -0.4rem;
}

.tooltip-container[data-popper-placement*='bottom'] .tooltip-arrow::before {
  position: absolute;
  top: -1px;
  border-color: transparent transparent var(--tooltipBorder) transparent;
  border-width: 0 0.5rem 0.4rem;
}

.tooltip-container[data-popper-placement*='bottom'] .tooltip-arrow::after {
  border-color: transparent transparent var(--tooltipBackground) transparent;
  border-width: 0 0.5rem 0.4rem;
}

.tooltip-container[data-popper-placement*='top'] .tooltip-arrow {
  bottom: 0;
  left: 0;
  margin-bottom: -1rem;
}

.tooltip-container[data-popper-placement*='top'] .tooltip-arrow::before {
  position: absolute;
  top: 1px;
  border-color: var(--tooltipBorder) transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0;
}

.tooltip-container[data-popper-placement*='top'] .tooltip-arrow::after {
  border-color: var(--tooltipBackground) transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0;
}

.tooltip-container[data-popper-placement*='right'] .tooltip-arrow {
  left: 0;
  margin-left: -0.7rem;
}

.tooltip-container[data-popper-placement*='right'] .tooltip-arrow::before {
  border-color: transparent var(--tooltipBorder) transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
}

.tooltip-container[data-popper-placement*='right'] .tooltip-arrow::after {
  top: 0;
  left: 6px;
  border-color: transparent var(--tooltipBackground) transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
}

.tooltip-container[data-popper-placement*='left'] .tooltip-arrow {
  right: 0;
  margin-right: -0.7rem;
}

.tooltip-container[data-popper-placement*='left'] .tooltip-arrow::before {
  border-color: transparent transparent transparent var(--tooltipBorder);
  border-width: 0.5rem 0 0.5rem 0.4em;
}

.tooltip-container[data-popper-placement*='left'] .tooltip-arrow::after {
  top: 0;
  left: 3px;
  border-color: transparent transparent transparent var(--tooltipBackground);
  border-width: 0.5rem 0 0.5rem 0.4em;
}
