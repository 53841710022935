@import 'constants/styles.constants.module';

.root {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  min-width: 100vw;
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $colorBackgroundAlpha90;
}

div.header {
  .title {
    display: flex;
    flex-basis: 100%;
    justify-content: left;
    font-size: $fontSizeTitle2;
  }

  .modalTitleCenter {
    justify-content: center;
    text-align: center;

    .modalTitle {
      padding-left: 2.05rem;
    }
  }

  .modalTitle {
    display: flex;
    padding-right: 2.05rem;
  }

  span.biggerTitle {
    font-weight: $fontWeightMedium;
    font-size: $fontSizeTitle1;
  }

  .crossBlock {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.bigger {
  margin-top: 2rem;
}

div.cardWrapperAlert {
  position: relative;
  top: 50%;
  left: 50%;
  width: 50rem;
  transform: translateX(-50%) translateY(-55%);
}

%cardwrapper {
  position: relative;
  top: 5%;
  left: 50%;
  max-width: 60rem;
  margin-bottom: 5%;
  transform: translateX(-50%);
}

div.cardWrapperFULL {
  @extend %cardwrapper;
  max-width: 95%;
}

div.cardWrapperXL {
  @extend %cardwrapper;
  max-width: 80rem;
}

div.cardWrapperMXL {
  @extend %cardwrapper;
  max-width: 70rem;
}

div.cardWrapperL {
  @extend %cardwrapper;
  max-width: 60rem;
}

div.cardWrapperM {
  @extend %cardwrapper;
  max-width: 40rem;
}

div.cardWrapperS {
  @extend %cardwrapper;
  max-width: 30rem;
}

div.card {
  z-index: 1001;
  min-height: 15rem;
  padding: 2rem 2rem 3rem;
}

div.autoHeight {
  height: auto;
  min-height: 10rem;
}

.noScroll {
  overflow: hidden;
}

.centerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.dialogSuccessIcon {
  height: 4rem;
  margin-bottom: 3rem;
  text-align: center;
}

.modalText {
  display: block;
  font-weight: $fontWeightLight;
  font-size: $fontSizeTitle3;
  text-align: center;

  &.left {
    text-align: left;
  }
}

.modalButtons {
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  button:first-child {
    margin-right: 1rem;
  }
}
