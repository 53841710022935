@import 'constants/styles.constants.module';

.root {
  margin: 0;
  font-weight: $fontWeightLight;
  font-size: $fontSizeTitle3;
  line-height: 1.5;
  text-align: left;

  @include max(s) {
    font-size: $fontSizeBiggestText;
  }
}
