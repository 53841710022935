@import 'constants/styles.constants.module';

.root {
  margin: 0;
  font-weight: $fontWeightMedium;
  font-size: $fontSizeTitle1;
  line-height: 1;
  text-align: left;

  @include max(xl) {
    font-size: $fontSizeTitle2;
  }

  @include max(s) {
    font-size: $fontSizeTitle3;
  }
}

.flex {
  display: flex;
}
